const apiRequest = async (url, data) => {
    try {
        return await fetch(`https://${process.env.REACT_APP_SERVER_IP}/${url} `, {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            dataType: "text",
            contentType: "text/plain",
            body: JSON.stringify(data),
        }).then(async (r) => {
            const response = await r.json();

            if (!response?.result) {
                throw new Error(response.error);
            }

            return response?.data;
        }).catch((error) => {
            throw new Error(error.message);
        });
    } catch (error) {
        console.error(error.message);

        throw new Error(error.message);
    }
};

export default apiRequest;