import React, { useState, useEffect } from 'react';
import { Colors } from '../styles/colors.js';
import "react-datepicker/dist/react-datepicker.css";
import "../styles/training.scss";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import apiRequest from '../utils/apiRequest.js';

const Subscribe = () => {
	const [contactName, setContactName] = useState("");
	const [contactEmail, setContactEmail] = useState("");
	const [feedbackInfo, setFeedbackInfo] = useState("");
	const [spinnerDisplay, setSpinnerDisplay] = useState("none");
	const [messageSubmitted, setMessageSubmitted] = useState(false);

	const updateContactName = (e) => {
		setContactName(e.target.value);
	}
	const updateContactEmail = (e) => {
		setContactEmail(e.target.value);
	}

	const handleMessageSubmit = async (e) => {
		try {

			e.preventDefault();
			setFeedbackInfo("");

			if (!contactName || !contactEmail) {
				setFeedbackInfo("Please complete all fields.")
				return;
			}

			setSpinnerDisplay("flex");
			setMessageSubmitted(true);

			await apiRequest('mailinglist/subscribe', {
				name: contactName,
				email: contactEmail
			});

			setFeedbackInfo(`Thanks ${contactName}, we've received your request.`);
		} catch (error) {
			setFeedbackInfo("Whoops, something went wrong, please try again later.")
		}

		setContactName("");
		setContactEmail("");
		setSpinnerDisplay("none");
	}


	return (
			<div className="subscribe-container">
			<h1 className="subscribe-title">
				Subscribe to our mailing list below
			</h1>

			<br/>

			<div className="subscribe-form">
				<div>
					<label className="subscribe-label">Name: </label>
					<br />
					<input className="subscribe-input" disabled={messageSubmitted} type={"text"} value={contactName} onChange={updateContactName} />
					<br />
					<br />
				</div>

				<div>
						<label className="subscribe-label">Email: </label>
					<br />
					<input className="subscribe-input" disabled={messageSubmitted} type={"text"} value={contactEmail} onChange={updateContactEmail} />
					<br />
					<br />
				</div>

				<div className="subscribe-submit-container">
					<button
						disabled={messageSubmitted}
						className="subscribe-button"
						type={"submit"}
						onClick={handleMessageSubmit}
					>
						Submit
					</button>

					<Loader
						color={Colors.pink}
						width={"30px"}
						height={"30px"}
						type="TailSpin"
						style={{
							marginLeft: "1.5vh",
							display: spinnerDisplay
						}}
					/>
					<p className="subscribe-feedback">{feedbackInfo}</p>
				</div>
			</div>
		</div>
	);
}


export default Subscribe;
