import React, { useState, useEffect } from 'react';
import { Colors } from '../styles/colors';
// import { InstagramFeed } from "../components/instaFeed/feed.js";

import wow from "../assets/images/social/workingOutWellness.png";
import spot from "../assets/images/social/spot.png";
import anchor from "../assets/images/social/anchor.png";
import fb from "../assets/images/social/f.png";
import insta from "../assets/images/social/i.png";
import phone from "../assets/images/social/phone.png";
import mail from "../assets/images/social/mail.png";

const Social = (props) => {


	const [t1Size, setT1Size] = useState("3vw");
	const [t2Size, setT2Size] = useState("2vw");
	const [pSize, setPSize] = useState("0.75vw");

	useEffect(() => {
		const d = props.deviceType;

		if (d === "tablet") {
			setT1Size("6vw");
			setT2Size("4vw");
			setPSize("2vw")
		}
		else if (d === "mobile") {
			setT1Size("8vw");
			setT2Size("7vw");
			setPSize("4vw")
		}

		const script = document.createElement('script');

		// TikTok Widget
		script.src = "https://static.elfsight.com/platform/platform.js";
		script.async = true;

		document.body.appendChild(script);

		document.querySelectorAll('a[href*="elfsight"]').forEach(anchor => {
			anchor.remove();
		});

		return () => {
			document.body.removeChild(script);
		}
	}, [props.deviceType]);

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: "column",
				backgroundColor: Colors.offBlack,
				minHeight: "93vh",
				color: Colors.offwhite,
				fontFamily: "RobotSlab",
				alignItems: "center"
			}}
		>
			<div
				style={{
					marginTop: props.deviceType === "desktop" ? "20vh" : "11vh",
					width: "75%",
					display: "flex",
					flexDirection: "column",
					alignContent: "start",
					// alignSelf: "center",
				}}
			>
				<div
					style={{
						flex: 1,
						display: "flex",
						flexDirection: "column",
						alignContent: "start",
						flexWrap: "wrap",
					}}
				>
					<h1
						style={{
							fontFamily: "bebas",
							fontWeight: 200,
							fontSize: t1Size,
							marginTop: 0,
							marginBottom: 0
						}}
					>
						Explore our social media
					</h1>
					{/* <InstagramFeed
						counter="6"
						token={process.env.REACT_APP_INSTAGRAM_TOKEN}
					/> */}

					<div className="elfsight-app-2de65a9c-9735-4d61-a9ec-587feff23086" data-elfsight-app-lazy></div>

				</div>
				<div
					style={{
						flex: 1,
						marginLeft: props.deviceType === "desktop" ? 0 : "3.25vw",
						width: props.deviceType === "100%" ? 0 : "91%",
						marginTop: props.deviceType === "desktop" ? 0 : "5vh",

					}}
				>
					<h1
						style={{
							fontFamily: "bebas",
							fontWeight: 200,
							fontSize: t1Size,
							marginTop: 20,
							marginBottom: 0
						}}
					>
						or Listen to our podcast
					</h1>

					<div
						style={{
							display: 'flex',
						}}
					>

						{
							props.deviceType === "mobile" ?
								null
								:
								(
									<img
										style={{
											width: "20vw",
											height: "20vw",
											borderRadius: "10px"
										}}
										className="img-responsive webpic"
										alt="article header"
										src={wow}
									/>
								)
						}


						<div style={{ marginLeft: "2vw", alignSelf: "center" }}>
							{
								props.deviceType === "mobile" ?
									(
										<img
											style={{
												width: "90%",
												height: "90%",
												borderRadius: "10px"
											}}
											className="img-responsive webpic"
											alt="article header"
											src={wow}
										/>
									)
									:
									(
										null
									)
							}

							<h2
								style={{
									fontFamily: "bebas",
									fontWeight: 200,
									fontSize: t2Size,
									marginTop: 0,
									marginBottom: 0
								}}
							>
								Working Out Wellness
							</h2>

							<p
								style={{
									fontSize: pSize,
									fontWeight: 200
								}}
							>
								Join Kate and Bree as they explore all things mental and physical health, bringing together two worlds and continuing their journey in Working Out Wellness.
							</p>

							<div
								style={{
									height: "4vh",
									display: "flex",
								}}
							>
								<a
									href={"https://open.spotify.com/show/4hSSht1thwJXTuEC5AKry0"}
									target="_blank"
									rel="noreferrer"
								>
									<img
										style={{
											height: "100%",
										}}
										className="img-responsive webpic"
										alt="article header"
										src={spot}
									/>
								</a>

								<a
									href={"https://anchor.fm/workingoutwellnesspodcast"}
									target="_blank"
									rel="noreferrer"
								>
									<img
										style={{
											marginLeft: "1vw",
											height: "100%",
											cursor: "pointer"
										}}
										className="img-responsive webpic"
										alt="article header"
										src={anchor}
									/>
								</a>
							</div>
							<div
								style={{
									height: props.deviceType === "desktop" ? "3vh" : "8vw",
									display: "flex",
									marginTop: "1.5vh",
									marginBottom: "3vh"
								}}
							>
								<a
									href={"https://www.facebook.com/Working-Out-Wellness-101049358943098"}
									target="_blank"
									rel="noreferrer"
								>
									<img
										style={{
											height: "100%",
										}}
										className="img-responsive webpic"
										alt="article header"
										src={fb}
									/>
								</a>
								<a
									href={"https://instagram.com/workingoutwellnesspodcast"}
									target="_blank"
									rel="noreferrer"
								>
									<img
										style={{
											height: "100%",
											marginLeft: "0.5vw",
										}}
										className="img-responsive webpic"
										alt="article header"
										src={insta}
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<a href="/subscribe">

				<button
					className="btn draw-border-2"
					style={{
						fontSize: t2Size,
						fontFamily: "bebas",
						marginTop: "10vh",
						backgroundColor: Colors.pink,
					}}
				>
					Subscribe to Our Mailing List
				</button>

			</a>

			{
				props.deviceType === "desktop" ?
					(

						<div
							style={{
								alignSelf: "center",
								display: "flex",
								marginTop: props.deviceType === "desktop" ? "10vh" : 0,
								justifyContent: "space-around",
								alignItems: "center",
							}}
						>
							<div
								style={{
									flex: 1,
									display: 'flex',
									width: "15vw",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<a
									href={"https://www.facebook.com/BossSquadTraining"}
									target="_blank"
									rel="noreferrer"
									style={{
										display: "flex",
										textDecoration: "none",
									}}
								>
									<img
										style={{
											height: "2vw",
											alignSelf: "center"
										}}
										className="img-responsive webpic"
										alt="article header"
										src={fb}
									/>
									<p
										style={{
											color: Colors.offwhite,
											fontFamily: "RobotoSlab",
											marginLeft: "0.5vw",
											fontSize: pSize,
											alignSelf: "center"
										}}
									>
										Boss Squad Training
									</p>
								</a>
							</div>


							<div
								style={{
									flex: 1,
									display: 'flex',
									width: "15vw",
									alignItems: "center",
									justifyContent: "center"
								}}
							>
								<a
									href={"https://www.instagram.com/BossSquadTraining"}
									target="_blank"
									rel="noreferrer"
									style={{ display: "flex", textDecoration: "none", }}
								>
									<img
										style={{
											height: "2vw",
											alignSelf: "center"
										}}
										className="img-responsive webpic"
										alt="article header"
										src={insta}
									/>
									<p
										style={{
											color: Colors.offwhite,
											fontFamily: "RobotoSlab",
											fontSize: pSize,
											alignSelf: "center",
											marginLeft: "0.5vw"
										}}
									>
										@BossSquadTraining
									</p>
								</a>
							</div>

							<div
								style={{
									flex: 1,
									display: 'flex',
									width: "15vw",
									alignItems: "center",
									justifyContent: "center"
								}}
							>
								<img
									style={{
										height: "2vw",
										alignSelf: "center"
									}}
									className="img-responsive webpic"
									alt="article header"
									src={phone}
								/>
								<p
									style={{
										color: Colors.offwhite,
										textDecoration: "none",
										fontFamily: "RobotoSlab",
										fontSize: pSize,
										alignSelf: "center",
										marginLeft: "0.5vw"
									}}
								>
									0480 283 009
								</p>
							</div>
							<div
								style={{
									flex: 1,
									display: 'flex',
									alignItems: "center",
									justifyContent: "center",
									width: "15vw",
								}}
							>
								<a
									href={"mailto: info@BossSquadTraining.com"}
									target="_blank"
									rel="noreferrer"
									style={{
										display: "flex",
										textDecoration: "none",
									}}
								>
									<img
										style={{
											height: "2vw",
											alignSelf: "center"
										}}
										className="img-responsive webpic"
										alt="article header"
										src={mail}
									/>
									<p
										style={{
											color: Colors.offwhite,
											fontFamily: "RobotoSlab",
											fontSize: pSize,
											alignSelf: "center",
											marginLeft: "0.5vw"
										}}
									>
										info@BossSquadTraining.com
									</p>
								</a>
							</div>
						</div >

					)
					:
					(
						props.deviceType === "tablet" ?
							(
								<div
									style={{
										width: "75%"
									}}>

									<div
										style={{
											alignSelf: "center",
											display: "flex",
											marginTop: props.deviceType === "desktop" ? "10vh" : 0,
											justifyContent: "space-around",
											alignItems: "center",
										}}
									>
										<div
											style={{
												flex: 1,
												display: 'flex',
												width: "15vw",
												alignItems: "center",
												justifyContent: "center",
											}}
										>
											<a
												href={"https://www.facebook.com/BossSquadTraining"}
												target="_blank"
												rel="noreferrer"
												style={{
													display: "flex",
													textDecoration: "none",
												}}
											>
												<img
													style={{
														height: "8vw",
														alignSelf: "center"
													}}
													className="img-responsive webpic"
													alt="article header"
													src={fb}
												/>
												<p
													style={{
														color: Colors.offwhite,
														fontFamily: "RobotoSlab",
														marginLeft: "0.5vw",
														fontSize: pSize,
														alignSelf: "center"
													}}
												>
													Boss Squad Training
												</p>
											</a>
										</div>

										<div
											style={{
												flex: 1,
												display: 'flex',
												width: "15vw",
												alignItems: "center",
												justifyContent: "center"
											}}
										>
											<a
												href={"https://www.instagram.com/BossSquadTraining"}
												target="_blank"
												rel="noreferrer"
												style={{ display: "flex", textDecoration: "none", }}
											>
												<img
													style={{
														height: "8vw",
														alignSelf: "center"
													}}
													className="img-responsive webpic"
													alt="article header"
													src={insta}
												/>
												<p
													style={{
														color: Colors.offwhite,
														fontFamily: "RobotoSlab",
														fontSize: pSize,
														alignSelf: "center",
														marginLeft: "0.5vw"
													}}
												>
													@BossSquadTraining
												</p>
											</a>
										</div>

									</div >

									<div
										style={{
											alignSelf: "center",
											display: "flex",
											marginTop: props.deviceType === "desktop" ? "10vh" : 0,
											justifyContent: "space-around",
											alignItems: "center",
										}}
									>
										<div
											style={{
												flex: 1,
												display: 'flex',
												width: "15vw",
												alignItems: "center",
												justifyContent: "center"
											}}
										>
											<img
												style={{
													height: "8vw",
													alignSelf: "center"
												}}
												className="img-responsive webpic"
												alt="article header"
												src={phone}
											/>
											<p
												style={{
													color: Colors.offwhite,
													textDecoration: "none",
													fontFamily: "RobotoSlab",
													fontSize: pSize,
													alignSelf: "center",
													marginLeft: "0.5vw"
												}}
											>
												0480 283 009
											</p>
										</div>

										<div
											style={{
												flex: 1,
												display: 'flex',
												alignItems: "center",
												justifyContent: "center",
												width: "15vw",
											}}
										>
											<a
												href={"mailto: info@BossSquadTraining.com"}
												target="_blank"
												rel="noreferrer"
												style={{
													display: "flex",
													textDecoration: "none",
												}}
											>
												<img
													style={{
														height: "8vw",
														alignSelf: "center"
													}}
													className="img-responsive webpic"
													alt="article header"
													src={mail}
												/>
												<p
													style={{
														color: Colors.offwhite,
														fontFamily: "RobotoSlab",
														fontSize: pSize,
														alignSelf: "center",
														marginLeft: "0.5vw"
													}}
												>
													info@BossSquadTraining.com
												</p>
											</a>
										</div>
									</div >

								</div>
							)
							:
							(

								<div
									style={{
										width: "75%",
									}}
								>
									<div
										style={{
											flex: 1,
											display: 'flex',
											alignItems: "center",
											justifyContent: "center"
										}}
									>
										<img
											style={{
												height: "8vw",
												alignSelf: "center"
											}}
											className="img-responsive webpic"
											alt="article header"
											src={phone}
										/>
										<p
											style={{
												color: Colors.offwhite,
												textDecoration: "none",
												fontFamily: "RobotoSlab",
												fontSize: pSize,
												alignSelf: "center",
												marginLeft: "0.5vw"
											}}
										>
											0480 283 009
										</p>
									</div>

									<div
										style={{
											flex: 1,
											display: 'flex',
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<a
											href={"https://www.facebook.com/BossSquadTraining"}
											target="_blank"
											rel="noreferrer"
											style={{
												display: "flex",
												textDecoration: "none",
											}}
										>
											<img
												style={{
													height: "8vw",
													alignSelf: "center"
												}}
												className="img-responsive webpic"
												alt="article header"
												src={fb}
											/>
											<p
												style={{
													color: Colors.offwhite,
													fontFamily: "RobotoSlab",
													marginLeft: "0.5vw",
													fontSize: pSize,
													alignSelf: "center"
												}}
											>
												Boss Squad Training
											</p>
										</a>
									</div>
									<div
										style={{
											flex: 1,
											display: 'flex',
											alignItems: "center",
											justifyContent: "center"
										}}
									>
										<a
											href={"https://www.instagram.com/BossSquadTraining"}
											target="_blank"
											rel="noreferrer"
											style={{ display: "flex", textDecoration: "none", }}
										>
											<img
												style={{
													height: "8vw",
													alignSelf: "center"
												}}
												className="img-responsive webpic"
												alt="article header"
												src={insta}
											/>
											<p
												style={{
													color: Colors.offwhite,
													fontFamily: "RobotoSlab",
													fontSize: pSize,
													alignSelf: "center",
													marginLeft: "0.5vw"
												}}
											>
												@BossSquadTraining
											</p>
										</a>
									</div>
									<div
										style={{
											flex: 1,
											display: 'flex',
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<a
											href={"mailto: info@BossSquadTraining.com"}
											target="_blank"
											rel="noreferrer"
											style={{
												display: "flex",
												textDecoration: "none",
											}}
										>
											<img
												style={{
													height: "8vw",
													alignSelf: "center"
												}}
												className="img-responsive webpic"
												alt="article header"
												src={mail}
											/>
											<p
												style={{
													color: Colors.offwhite,
													fontFamily: "RobotoSlab",
													fontSize: pSize,
													alignSelf: "center",
													marginLeft: "0.5vw"
												}}
											>
												info@BossSquadTraining.com
											</p>
										</a>
									</div>


								</div>
							)
					)
			}




		</div >
	);
}

export default Social;