import React, { useEffect, useState } from 'react';
import { Colors } from '../styles/colors.js';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import apiRequest from '../utils/apiRequest.js';

const Training = ({ deviceType }) => {
	const [selectedPackage, setSelectedPackage] = useState(null);
	const [showForm, setShowForm] = useState(false);
	const [notification, setNotification] = useState(null);
	const [fadeIn, setFadeIn] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const packages = [
		{
			id: 1, category: 'Online Fitness Coaching', tier: 'Getting you Started', price: 70, duration: '6 weeks minimum', description: 'Online Fitness Coaching Only, 6 weeks minimum', details: "This is for anyone looking to start a new health journey with extra guidance and accountability.",
			whatsIncluded: [
				'Initial online consultation and goal setting',
				'In-home or gym fitness program overview and strategy',
				'6x week tailored fitness program up to five sessions weekly',
				'Weekly online check-ins / program reviews with your coach',
				'Tailored strength, endurance, flexibility and composition assessment prescription and monitoring',
				'Progression / results tracking and reports (every 6 weeks)',
				'Access to our private Boss Squad Training Facebook group',
				'Access to our wellness library, blog posts, podcasts, newsletters, etc'
			]
		},
		{
			id: 2, category: 'Online Fitness Coaching', tier: 'Body Balancing', price: 55, duration: '6 weeks minimum', description: 'Online Fitness Coaching Only, 6 weeks minimum', details: "This is for anyone looking to boost their training and fast-track their results.",
			whatsIncluded: [
				'Initial online consultation and goal setting',
				'In-home or gym fitness program overview and strategy',
				'6x week tailored fitness program up to five sessions weekly',
				'Fortnightly online check-ins / program reviews with your coach',
				'Tailored strength, endurance, flexibility and composition assessment prescription and monitoring',
				'Progression / results tracking and reports (every 6 weeks)',
				'Access to our private Boss Squad Training Facebook group',
				'Access to our wellness library, blog posts, podcasts, newsletters, etc'
			]
		},
		{
			id: 3, category: 'Online Fitness Coaching', tier: 'Totally Boss', price: 35, duration: '6 weeks minimum', description: 'Online Fitness Coaching Only, 6 weeks minimum', details: "This is for someone with experience in fitness training, looking for more accountability.",
			whatsIncluded: [
				'Initial online consultation and goal setting',
				'In-home or gym fitness program overview and strategy',
				'Monthly online check-ins / program reviews with your coach',
				'Tailored strength, endurance, flexibility and composition assessment prescription and monitoring',
				'Progression / results tracking',
				'Access to our private Boss Squad Training Facebook group',
				'Access to our wellness library, blog posts, podcasts, newsletters, etc'
			]
		},
		{
			id: 4, category: 'Online Nutrition Coaching', tier: 'Getting you Started', price: 70, duration: '6 weeks minimum', description: 'Online Nutrition Coaching Only, 6 weeks minimum', details: "This is for anyone looking to build their nutritional literacy from the ground up and stay accountable to a tailored nutrition plan.",
			whatsIncluded: [
				'Initial online consultation and goal setting',
				'Nutrition program overview and strategy',
				'6x week tailored nutrition program up to five days',
				'Up to five programmed meals / snacks per day',
				'Programmed recipes provided with flexible meal options included',
				'Tailored calorie and macronutrient calculation and monitoring',
				'Weekly online check-ins / program reviews with your coach',
				'Tailored composition assessment prescription and monitoring',
				'Progression / results tracking and reports (every 6 weeks)',
				'Access to our private Boss Squad Training Facebook group',
				'Access to our wellness library, blog posts, podcasts, newsletters, etc'
			]
		},
		{
			id: 5, category: 'Online Nutrition Coaching', tier: 'Body Balancing', price: 55, duration: '6 weeks minimum', description: 'Online Nutrition Coaching Only, 6 weeks minimum', details: "A tailored meal plan package designed to boost your nutritional literacy and recipe repertoire and journey results!",
			whatsIncluded: [
				'Initial online consultation and goal setting',
				'Nutrition program overview and strategy',
				'6x week tailored nutrition program up to five days',
				'Up to three programmed meals / snacks per day',
				'Programmed recipes provided with flexible meal options included',
				'Tailored calorie and macronutrient calculation and monitoring',
				'Fortnightly online check-ins / program reviews with your coach',
				'Tailored composition assessment prescription and monitoring',
				'Progression / results tracking and reports (every 6 weeks)',
				'Access to our private Boss Squad Training Facebook group',
				'Access to our wellness library, blog posts, podcasts, newsletters, etc'
			]
		},
		{
			id: 6, category: 'Online Nutrition Coaching', tier: 'Totally Boss', price: 35, duration: '6 weeks minimum', description: 'Online Nutrition Coaching Only, 6 weeks minimum', details: "Stay accountable, have your food tracking tailored to you and monitored throughout your journey.",
			whatsIncluded: [
				'Initial online consultation and goal setting',
				'Nutrition program overview and strategy',
				'Weekly emailed recipes with flexible meal options included',
				'Tailored calorie and macronutrient calculation and monitoring',
				'Two online check-ins / program reviews with your coach',
				'Tailored composition assessment prescription and monitoring',
				'Access to our private Boss Squad Training Facebook group',
				'Access to our wellness library, blog posts, podcasts, newsletters, etc'
			]
		},
		{
			id: 7, category: 'Combined Fitness & Nutrition Coaching', tier: 'Getting you Started', price: 95, duration: '12 weeks minimum', description: 'Combined Fitness and Nutrition Coaching, 12 weeks minimum', details: "A combined approach to fitness and nutrition for getting you started with extra guidance.",
			whatsIncluded: [
				'Initial online consultation and goal setting',
				'In-home or gym fitness and nutrition program overview and strategy',
				'12x week tailored fitness program up to five sessions weekly',
				'12x week tailored nutrition program up to five days',
				'Up to five programmed meals/ snacks per day',
				'Programmed recipes provided with flexible meal options included',
				'Tailored calorie and macronutrient calculation and monitoring',
				'Weekly online check-ins/ program reviews with your coach',
				'Tailored strength, endurance, flexibility and composition assessment prescription and monitoring',
				'Progression/ results tracking and reports (every 6 weeks)',
				'Access to our private Boss Squad Training Facebook group',
				'Access to our wellness library, blog posts, podcasts, newsletters, etc'
			]
		},
		{
			id: 8, category: 'Combined Fitness & Nutrition Coaching', tier: 'Body Balancing', price: 70, duration: '12 weeks minimum', description: 'Combined Fitness and Nutrition Coaching, 12 weeks minimum', details: "A combined approach to fast-track your fitness and nutrition journey.",
			whatsIncluded: [
				'Initial online consultation and goal setting',
				'In-home or gym fitness and nutrition program overview and strategy',
				'12x week tailored fitness program up to three sessions weekly',
				'12x week tailored nutrition program up to three days',
				'Up to three programmed meals per day',
				'Programmed recipes provided with flexible meal options included',
				'Tailored calorie and macronutrient calculation and monitoring',
				'Fortnightly online check-ins / program reviews with your coach',
				'Tailored strength, endurance, flexibility and composition assessment prescription and monitoring',
				'Progression/ results tracking and reports (every 6 weeks)',
				'Access to our private Boss Squad Training Facebook group',
				'Access to our wellness library, blog posts, podcasts, newsletters, etc'
			]
		},
		{
			id: 9, category: 'Combined Fitness & Nutrition Coaching', tier: 'Totally Boss', price: 55, duration: '12 weeks minimum', description: 'Combined Fitness and Nutrition Coaching, 12 weeks minimum', details: "For experienced individuals who want accountability across both fitness and nutrition.",
			whatsIncluded: [
				'Initial online consultation and goal setting',
				'In-home or gym fitness and nutrition program overview and strategy',
				'Tailored calorie and macronutrient calculation and monitoring',
				'Weekly emailed recipes with flexible meal options included',
				'Monthly online check-ins / program reviews with your coach',
				'Tailored strength, endurance, flexibility and composition assessment prescription and monitoring',
				'Progression / results tracking',
				'Access to our private Boss Squad Training Facebook group',
				'Access to our wellness library, blog posts, podcasts, newsletters, etc'

			]
		},
	];

	const handlePackageClick = (selectedPackage) => {
		setSelectedPackage(selectedPackage);
		setShowForm(true);
	};

	const handleCloseModal = () => {
		setSelectedPackage(null);
		setShowForm(false);
		setNotification(null);
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		const name = e.target.name.value;
		const email = e.target.email.value;
		setIsLoading(true);

		try {
			await apiRequest('mailinglist/trainingenquiry', {
				name,
				email,
				packageName: `${selectedPackage.category} - ${selectedPackage.tier}`
			});
			setNotification({ type: 'success', message: `✔️ Thanks ${name}, we've received your request and will be in touch shortly.` });
		} catch (error) {
			setNotification({ type: 'error', message: `❌ Sorry ${name}, there was error processing your request. Email us directly at info@bosssquadtraining.com and we'll be in touch.` });
		}

		setIsLoading(false);
		setFadeIn(true);
		setShowForm(false);
		setSelectedPackage(null);
	};

	useEffect(() => {
		if (notification) {
			setFadeIn(true);
			const timer = setTimeout(() => {
				setFadeIn(false);
			}, 10000);
			return () => clearTimeout(timer);
		}
	}, [notification]);

	const isMobile = deviceType === "mobile" || deviceType === "tablet";

	const renderPackagesByCategory = (category) => {
		return packages.filter(pkg => pkg.category === category).map((packageItem) => (
			<div
				key={packageItem.id}
				style={{
					margin: "1rem",
					padding: "1rem",
					borderRadius: "8px",
					width: "200px",
					textAlign: "center",
					cursor: "pointer",
					backgroundColor: Colors.pink,
				}}
				onClick={() => handlePackageClick(packageItem)}
			>
				<h2 style={{ color: Colors.offwhite, fontFamily: "bebas", fontWeight: 200 }}>{packageItem.tier}</h2>
				<p style={{ color: Colors.offwhite, fontSize: "0.8rem" }}>{packageItem.details}</p>
				<p style={{ color: Colors.offwhite, fontWeight: "bold" }}>${packageItem.price}/week</p>
				<p style={{ color: Colors.offwhite, fontSize: "0.8rem" }}>{packageItem.duration}</p>
			</div>
		));
	};

	return (
		<div
			style={{
				width: "100vw",
				marginTop: isMobile ? 0 : "15vh",
				display: "flex",
				alignItems: "center",
				flexDirection: "column",
				backgroundColor: Colors.black,
			}}
		>
			<h1
				style={{
					color: Colors.offwhite,
					fontFamily: "bebas",
					fontWeight: 200,
					marginBottom: 0,
					marginTop: isMobile ? '10vh' : "5vh",
				}}
			>
				Check out our Coaching Packages!
			</h1>

			<p
				style={{
					color: Colors.offwhite,
					marginBottom: 0,
					width: '80vw',
					maxWidth: 600,
					textAlign: "center",
				}}
			>
				No matter what goal you have in mind, we have an online coaching package for you. Passionate about health and wellness, we are dedicated to providing a fantastic service to our Squad. Can't find a package that suits you? Reach out to us to find out how we can tailor a package specifically for you.
			</p>

			<h2 style={{ color: Colors.offwhite, fontFamily: "bebas", marginTop: "3vh", marginBottom: 0, fontWeight: 200, }}>Online Fitness Coaching Packages</h2>
			<div
				style={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
				}}
			>
				{renderPackagesByCategory('Online Fitness Coaching')}
			</div>

			<h2 style={{ color: Colors.offwhite, fontFamily: "bebas", marginTop: "3vh", marginBottom: 0, fontWeight: 200, }}>Online Nutrition Coaching Packages</h2>
			<div
				style={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
				}}
			>
				{renderPackagesByCategory('Online Nutrition Coaching')}
			</div>

			<h2 style={{ color: Colors.offwhite, fontFamily: "bebas", marginTop: "3vh", marginBottom: 0, fontWeight: 200, }}>Combined Fitness & Nutrition Coaching Packages</h2>
			<div
				style={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
				}}
			>
				{renderPackagesByCategory('Combined Fitness & Nutrition Coaching')}
			</div>

			<div
				style={{
					position: "fixed",
					left: 50,
					maxWidth: "calc(100vw - 100px)",
					bottom: 50,
					marginTop: "2rem",
					padding: "1rem",
					borderRadius: "8px",
					backgroundColor: Colors.offwhite,
					color: notification?.type === 'success' ? 'rgb(44 171 4)' : 'rgb(205 9 9)',
					fontFamily: "bebas",
					fontWeight: 200,
					pointerEvents: "none",
					opacity: fadeIn ? 1 : 0,
					boxShadow: "rgb(0 0 0 / 59%) 0px 0px 11px 7px",
					transition: "opacity 0.67s ease-in-out",
				}}
			>
				{notification?.message || ''}
			</div>

			{showForm && selectedPackage && (
				<div
					style={{
						position: "fixed",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						backgroundColor: Colors.pink,
						border: "2px solid #00000085",
						boxShadow: "rgb(0 0 0 / 78%) 0px 0px 20px 16px",
						padding: "2rem",
						borderRadius: "8px",
						color: Colors.offwhite,
					}}
				>
					<h2>{`${selectedPackage.category} - ${selectedPackage.tier}`}</h2>

					<h4>What's Included:</h4>

					<ul>
						{selectedPackage.whatsIncluded.map((item, index) => (
							<li key={index}>{item}</li>
						))}
					</ul>

					<h4>${selectedPackage.price}/week</h4>

					<p style={{
						maxWidth: 300
					}}>Enter your details below and we'll be in touch to arrange your training program details.</p>
					<br />

					<form onSubmit={(e) => handleFormSubmit(e)}>
						<div style={{ marginBottom: "1rem" }}>
							<label>Name:</label>
							<input type="text" name="name" required style={{ marginLeft: "10px" }} />
						</div>
						<div style={{ marginBottom: "1rem" }}>
							<label>Email:</label>
							<input type="email" name="email" required style={{ marginLeft: "10px" }} />
						</div>
						<br />
						<button
							type="submit"
							style={{
								float: "right",
								fontWeight: 'bold',
								borderRadius: "5px",
								backgroundColor: Colors.offwhite,
								color: Colors.black,
								padding: "0.5rem 1rem",
								border: "none",
								cursor: "pointer",
								marginTop: "10px",
								display: "flex",
								marginLeft: "10px",
							}}
							disabled={isLoading}
						>
							<Loader
								type="TailSpin"
								color={Colors.black}
								height={isLoading ? 12 : 0}
								width={isLoading ? 12 : 0}
								style={{
									color: Colors.black,
									marginRight: "10px",
									transition: "all 0.5s ease",
									verticalAlign: "middle",
									opacity: isLoading ? '1' : '0',
								}}
							/>
							Submit
						</button>
						<button
							onClick={handleCloseModal}
							type="button"
							style={{
								float: "right",
								fontWeight: 'bold',
								borderRadius: "5px",
								backgroundColor: '#cbcbcb',
								color: Colors.black,
								padding: "0.5rem 1rem",
								border: "none",
								cursor: "pointer",
								marginLeft: "10px",
								marginTop: "10px",
							}}
						>
							Cancel
						</button>
					</form>
				</div>
			)}
		</div>
	);
};

export default Training;
