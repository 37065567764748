import React, { useEffect, useState } from 'react';
import { Colors } from '../styles/colors.js';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import bootyBandImage from "../assets/images/merch/bootyBands.jpg";
import yogaMatt from "../assets/images/merch/yogamatt.jpg";
import gymTowel from "../assets/images/merch/gymTowel.jpg";
import apiRequest from '../utils/apiRequest.js';

const Merch = ({ deviceType }) => {
	const [selectedItem, setSelectedItem] = useState(null);
	const [showForm, setShowForm] = useState(false);
	const [size, setSize] = useState('');
	const [notification, setNotification] = useState(null);
	const [fadeIn, setFadeIn] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	// const items = [
	// 	{ id: 1, price: 50, name: 'Tshirt 1', image: tshirt1, details: 'Comfortable cotton t-shirt in various sizes.' },
	// 	{ id: 2, price: 50, name: 'Tshirt 2', image: tshirt2, details: 'Cool design t-shirt with a modern fit.' },
	// 	{ id: 3, price: 50, name: 'Goodie Bag 1', image: goodiebag1, details: 'Goodie bag full of surprises for everyone.' },
	// 	{ id: 4, price: 50, name: 'Goodie Bag 2', image: goodiebag2, details: 'Goodie bag 2 with exclusive items.' },
	// 	{ id: 5, price: 50, name: 'Hoodie', image: hoodie, details: 'Warm hoodie perfect for all seasons.' },
	// 	{ id: 6, price: 50, name: 'Yoga Mat', image: yogaMatt, details: 'High-quality yoga mat for your workout.' },
	// ];

	const items = [
		{ id: 1, price: 15, name: 'BST Gym Towel (white)', image: gymTowel, details: "Made from the best moisture wicking fabric, our BST printed gym towels make an awesome workout buddy. Machine washable and easy to fit in your workout bag, you can be sure it's a great gift for a friend too!" },
		{ id: 1, price: 29.99, name: 'BST Yoga Mat (black)', image: yogaMatt, details: "Printed with our Boss Squad Training logo, our soft foam yoga matts are the perfect pairing for your favourite yoga, pilates and group fitness classes." },
		{ id: 1, price: 49.99, name: 'BST Booty Bands', image: bootyBandImage, details: "Thick in width, these bands DO NOT ROLL, making them easy and comfortable to use. Made from a fast drying, sweat absorbent cotton / elastic blend, our BST booty bands are machine washable and can withstand stretching to almost double their length." },
	];

	const handleItemClick = (item) => {
		setSelectedItem(item);
	};

	const handlePurchaseClick = (item) => {
		setSelectedItem(item);
		setShowForm(true);
	};

	const handleCloseModal = () => {
		setSelectedItem(null);
		setShowForm(false);
		setSize('');
		setNotification(null);
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		const name = e.target.name.value;
		const email = e.target.email.value;
		setIsLoading(true);

		try {
			await apiRequest('mailinglist/merchenquiry', {
				name,
				email,
				itemName: selectedItem.name,
				size: size || null
			});
			setNotification({ type: 'success', message: `✔️ Thanks ${name}, we've received your request and will be in touch shortly.` });
		} catch (error) {
			setNotification({ type: 'error', message: `❌ Sorry ${name}, there was error processing your request. Email us directly at info@bosssquadtraining.com and we'll be in touch.` });
		}

		setIsLoading(false);
		setSize('');
		setFadeIn(true);
		setShowForm(false);
		setSelectedItem(null);
	};

	useEffect(() => {
		if (notification) {
			setFadeIn(true);
			const timer = setTimeout(() => {
				setFadeIn(false);
			}, 10000);
			return () => clearTimeout(timer);
		}
	}, [notification]);

	const isMobile = deviceType === "mobile" || deviceType === "tablet";

	return (
		<div
			style={{
				width: "100vw",
				marginTop: isMobile ? 0 : "15vh",
				display: "flex",
				alignItems: "center",
				flexDirection: "column",
				backgroundColor: Colors.black,
			}}
		>
			<h1
				style={{
					color: Colors.offwhite,
					fontFamily: "bebas",
					fontWeight: 200,
					marginBottom: 0,
					marginTop: isMobile ? '10vh' : "5vh",
				}}
			>
				Check out our Merch!
			</h1>

			<p
				style={{
					color: Colors.offwhite,
					marginBottom: 0,
					width: '80vw',
					maxWidth: 600,
					textAlign: "center",
				}}
			>
				Style your workouts with our exclusive BST Merchandise.
			</p>
			<div
				style={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					marginTop: "3vh",
				}}
			>
				{items.map((item) => (
					<div
						key={item.id}
						style={{
							margin: "1rem",
							padding: "1rem",
							borderRadius: "8px",
							width: "200px",
							textAlign: "center",
							cursor: "pointer",
							backgroundColor: Colors.pink,
						}}
						onClick={() => handleItemClick(item)}
					>
						<img
							src={item.image}
							alt={item.name}
							style={{ width: "100%", height: "auto", borderRadius: "8px" }}
						/>
						<h2 style={{ color: Colors.offwhite, fontFamily: "bebas" }}>{item.name}</h2>

						<p style={{ color: Colors.offwhite, fontSize: "0.8rem" }}>{item.details}</p>

						<button
							onClick={(e) => {
								e.stopPropagation();
								handlePurchaseClick(item);
							}}
							style={{
								fontWeight: 'bold',
								borderRadius: "5px",
								backgroundColor: Colors.offwhite,
								color: Colors.offBlack,
								padding: "0.5rem 1rem",
								border: "none",
								cursor: "pointer",
								marginTop: "10px",
							}}
						>
							Purchase
						</button>
					</div>
				))}
			</div>

			<div
				style={{
					position: "fixed",
					left: 50,
					bottom: 50,
					maxWidth: "calc(100vw - 100px)",
					marginTop: "2rem",
					padding: "1rem",
					borderRadius: "8px",
					backgroundColor: Colors.offwhite,
					color: notification?.type === 'success' ? 'rgb(44 171 4)' : 'rgb(205 9 9)',
					fontFamily: "bebas",
					pointerEvents: "none",
					opacity: fadeIn ? 1 : 0,
					boxShadow: "rgb(0 0 0 / 59%) 0px 0px 11px 7px",
					transition: "opacity 0.67s ease-in-out",
				}}
			>
				{notification?.message || ''}
			</div>

			{showForm && selectedItem && (
				<div
					style={{
						position: "fixed",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						backgroundColor: Colors.pink,
						border: "2px solid #00000085",
						boxShadow: "rgb(0 0 0 / 78%) 0px 0px 20px 16px",
						padding: "2rem",
						borderRadius: "8px",
						color: Colors.offwhite,
					}}
				>
					<h2>{selectedItem.name}</h2>

					<h4>${selectedItem.price}</h4>

					<p style={{
						maxWidth: 300
					}}>Enter your details below and we'll be in touch to arrange shipping and payment details.</p>
					<br />

					<form onSubmit={(e) => handleFormSubmit(e)}>
						<div style={{ marginBottom: "1rem" }}>
							<label>Name:</label>
							<input type="text" name="name" required style={{ marginLeft: "10px" }} />
						</div>
						<div style={{ marginBottom: "1rem" }}>
							<label>Email:</label>
							<input type="email" name="email" required style={{ marginLeft: "10px" }} />
						</div>
						{(selectedItem.name.includes('Tshirt') || selectedItem.name === 'Hoodie') && (
							<div style={{ marginBottom: "1rem" }}>
								<label>Size:</label>
								<select
									name="size"
									required
									value={size}
									onChange={(e) => setSize(e.target.value)}
									style={{ marginLeft: "10px" }}
								>
									<option value="">Select Size</option>
									<option value="xsmall">XS</option>
									<option value="small">S</option>
									<option value="medium">M</option>
									<option value="large">L</option>
									<option value="xlarge">XL</option>
								</select>
							</div>
						)}
						<br />
						<button
							type="submit"
							style={{
								float: "right",
								fontWeight: 'bold',
								borderRadius: "5px",
								backgroundColor: Colors.offwhite,
								color: Colors.black,
								padding: "0.5rem 1rem",
								border: "none",
								cursor: "pointer",
								marginTop: "10px",
								display: "flex",
								marginLeft: "10px",
							}}
							disabled={isLoading}
						>
							<Loader
								type="TailSpin"
								color={Colors.black}
								height={isLoading ? 12 : 0}
								width={isLoading ? 12 : 0}
								style={{
									color: Colors.black,
									marginRight: "10px",
									transition: "all 0.5s ease",
									verticalAlign: "middle",
									opacity: isLoading ? '1' : '0',
								}}
							/>
							Submit
						</button>
						<button
							onClick={handleCloseModal}
							type="button"
							style={{
								float: "right",
								fontWeight: 'bold',
								borderRadius: "5px",
								backgroundColor: '#cbcbcb',
								color: Colors.black,
								padding: "0.5rem 1rem",
								border: "none",
								cursor: "pointer",
								marginLeft: "10px",
								marginTop: "10px",
							}}
						>
							Cancel
						</button>
					</form>
				</div>
			)}
		</div>
	);
};

export default Merch;
