import React, { useState, useEffect, useRef, useCallback } from 'react';
import Modal from 'react-modal';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "../styles/home.scss";
import Fade from "react-reveal/Fade"
import { Header } from "../components/header.js";
import logo from "../assets/images/logowhite.png";
import landingVid from "../assets/images/home.webm";
import landingVidMp4 from "../assets/images/home.mp4";
import { Cart } from "../components/cart.js"
import chatHead from "../assets/images/chatHead.png";
import chatHeadGlow from "../assets/images/chatHeadGlow.png";
import arrowBlack from "../assets/images/arrowBlack.png";
import arrowWhite from "../assets/images/arrowWhite.png";
import { Colors } from "../styles/colors.js";
import MailIcon from '../assets/images/home/mail.png';
import MailIconPink from '../assets/images/home/mailPink.png';
import { useHistory } from 'react-router-dom';
import apiRequest from '../utils/apiRequest';

const Home = (props) => {
	const [t1Size, setT1Size] = useState("7vw");
	const [t2Size, setT2Size] = useState("2.5vw");
	const [t3Size, setT3Size] = useState("5vw");
	const [pSize, setPSize] = useState("1.5vw");
	const [p2Size, setP2Size] = useState("0.8vw");
	const [p3Size, setP3Size] = useState("1.5vw");
	const [mailSize, setMailSize] = useState("3vw");
	const [arrowSize, setArrowSize] = useState("2vw");
	const [deviceType, setDeviceType] = useState();

	const [contactName, setContactName] = useState("");
	const [contactEmail, setContactEmail] = useState("");
	const [contactMessage, setContactMessage] = useState("");
	const [messageSubmitted, setMessageSubmitted] = useState(false);
	const [spinnerDisplay, setSpinnerDisplay] = useState("none");

	const [blackFridayVisible, setBlackFridayVisible] = useState('none');

	const OnModalOpen = () => { }
	const updateContactName = (e) => {
		setContactName(e.target.value);
	}
	const updateContactEmail = (e) => {
		setContactEmail(e.target.value);
	}
	const updateContactMessage = (e) => {
		setContactMessage(e.target.value);
	}
	const handleMessageSubmit = async (e) => {
		try {

			e.preventDefault();
			setFeedbackInfo("");

			if (!contactName || !contactEmail || !contactMessage) {
				setFeedbackInfo("Please complete all fields.")
				return;
			}

			setSpinnerDisplay("flex");
			setMessageSubmitted(true);

			await apiRequest('mailinglist/homepageenquiry', {
				name: contactName,
				email: contactEmail,
				message: contactMessage
			});

			setFeedbackInfo("Your message has been received, we'll get back to you shortly.")
		} catch (error) {
			setFeedbackInfo("Whoops, something went wrong. You can email us directly at info@bosssquadtraining.com")
		}

		setSpinnerDisplay("none");
	}


	useEffect(() => {
		Modal.setAppElement('#root');
		setDeviceType(props.deviceType)

		const d = props.deviceType;

		if (d === "mobile" || d === "tablet") {
			setT1Size("12vw");
			setT2Size("5vw");
			setT3Size("9vw");
			setPSize("4vw")
			setP2Size("2.5vw")
			setP3Size("2.5vw")
			setMailSize("10vw")
			setArrowSize("7vw")
		}

		if (d === 'mobile') {
			setP3Size('3vw')
		}

		const blackFridayFinish = new Date('2022-11-28');

		if (new Date() < blackFridayFinish) {
			setBlackFridayVisible('flex');
		} else {
			setBlackFridayVisible('none');
		}


	}, [props.deviceType]);


	const [modalOpen, setModalOpen] = useState(false);
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [feedbackInfo, setFeedbackInfo] = useState("");

	const openModal = () => {
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
	};

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	};

	const handleMessageChange = (e) => {
		setMessage(e.target.value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setFeedbackInfo("");

		if (!email || !message) {
			setFeedbackInfo("Please complete all fields.");
			return;
		}

		setIsSubmitting(true);

		try {
			await apiRequest("https://test.com", {
				method: "POST",
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					email,
					message
				})
			});

			setFeedbackInfo("Thank you for subscribing!");
			setEmail("");
			setMessage("");
			closeModal();
		} catch (error) {
			setFeedbackInfo("Whoops, something went wrong. Please try again later.");
		} finally {
			setIsSubmitting(false);
		}
	};

	useEffect(() => {
		Modal.setAppElement('#root');
	}, []);


	return (
		<div>
			<div
				style={{
					width: '100vw',
					height: '100vh',
					position: 'absolute',
					background: 'rgb(0 0 0 / 39%)',
					zIndex: '9999999999999999999999999',
					display: blackFridayVisible,
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				{/* <div

					style={{
						width: '500px',
						maxWidth: '80%',
						background: Colors.pink,
						borderRadius: '10px',
						display: 'flex',
						textAlign: 'center',
						flexDirection: 'column',
						fontFamily: "Roboto",
						justifyItems: 'center',
						paddingLeft: 10,
						paddingRight: 10,
						paddingBottom: 15
					}}
				>
					<p
						onClick={() => setBlackFridayVisible('none')}
						style={{
							textAlign: 'end',
							margin: 0,
							cursor: 'pointer',
							fontFamily: "arial",
							fontSize: t3Size
						}}
					>
						x
					</p>
					<h1
						style={{
							margin: -10,
							fontFamily: "bebas",
							fontSize: t3Size,
						}}>PSSSST!</h1>

					<p style={{ fontSize: p3Size }}>We're having a Black Friday sale at our merch store - snag some swag at a wicked 30% off!</p>
					<p style={{ fontSize: p3Size }}>Use code BSTBLACKFRIDAY at the checkout. Hurry! Offer ends soon!</p>
					<br />
					<a
						style={{
							color: Colors.offBlack,
							fontSize: t2Size,
							fontFamily: "bebas",
						}}
						href="http://spaces.chec.io/31600">Take me there!</a>

				</div> */}

			</div>

			{
				(deviceType === "mobile" || deviceType === "tablet") ?
					(
						<Modal
							isOpen={modalOpen}
							onAfterOpen={OnModalOpen}
							onRequestClose={() => setModalOpen(false)}
							style={{
								overlay: {
									background: "rgba(0,0,0,0.75)"
								},
								content: {
									width: "80vw",
									top: '50%',
									left: '50%',
									right: 'auto',
									bottom: 'auto',
									marginRight: '-50%',
									transform: 'translate(-50%, -50%)',
									display: "flex",
									justifyContent: "center",
								},
							}}
						>
							<div
								style={{
									width: "100%",
									alignSelf: "center",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
								}}
							>



								<form
									onSubmit={handleMessageSubmit}
									style={{
										fontFamily: "bebas",
										fontSize: "1.5vw",
										display: "flex",
										flexDirection: "column",
									}}
								>
									<h2 style={{ marginBottom: 0, fontSize: "4vh" }}>Got a question?<br />Let us know</h2>
									<div style={{ height: "2vh" }} />
									<label style={{ fontSize: "3vh" }}>Name: </label>
									<input disabled={messageSubmitted} type={"text"} value={contactName} onChange={updateContactName} />
									<div style={{ height: "2vh" }} />

									<label style={{ fontSize: "3vh" }} >Email: </label>
									<input disabled={messageSubmitted} type={"email"} value={contactEmail} onChange={updateContactEmail} />
									<div style={{ height: "2vh" }} />

									<label style={{ fontSize: "3vh" }} >Message:</label>
									<textarea disabled={messageSubmitted} style={{ width: "100%", height: "30%" }} value={contactMessage} onChange={updateContactMessage} />
									<div style={{ height: "2vh" }} />

									<div style={{ display: "flex", overflow: "hidden", alignItems: "center" }} >
										<button disabled={messageSubmitted} style={{ height: "5vh", fontFamily: "bebas", fontSize: "3vh" }} type={"submit"}>Submit</button>
										<Loader
											color={Colors.pink}
											width={"3vh"}
											height={"3vh"}
											type="TailSpin"
											style={{
												marginLeft: "1.5vh",
												display: spinnerDisplay
											}}
										/>
										<p style={{ marginTop: 0, marginBottom: 0, marginLeft: "1.5vh", fontFamily: "Roboto", fontSize: "1.5vh" }}>{feedbackInfo}</p>
									</div>

									<div style={{ height: "5vh" }} />

								</form>

							</div>
						</Modal>
					)
					:
					(
						<Modal
							isOpen={modalOpen}
							onAfterOpen={OnModalOpen}
							onRequestClose={() => setModalOpen(false)}
							style={{
								overlay: {
									background: "rgba(0,0,0,0.75)"
								},
								content: {
									width: "25vw",
									top: '50%',
									left: '50%',
									right: 'auto',
									bottom: 'auto',
									marginRight: '-50%',
									transform: 'translate(-50%, -50%)',
									display: "flex",
									justifyContent: "center",
								},
							}}
						>
							<div
								style={{
									width: "80%",
									alignSelf: "center",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center"
								}}
							>

								<form
									onSubmit={handleMessageSubmit}
									style={{
										fontFamily: "bebas",
										fontSize: "1.5vw",
										height: "75%",
										display: "flex",
										flexDirection: "column"
									}}
								>
									<h2 style={{ marginBottom: 0 }}>Got a question?<br />Let us know</h2>
									<div style={{ height: "2.5vh" }} />
									<label>Name: </label>
									<input disabled={messageSubmitted} type={"text"} value={contactName} onChange={updateContactName} />
									<div style={{ height: "2vh" }} />

									<label>Email: </label>
									<input disabled={messageSubmitted} type={"email"} value={contactEmail} onChange={updateContactEmail} />
									<div style={{ height: "2vh" }} />

									<label>Message:</label>
									<textarea disabled={messageSubmitted} style={{ width: "100%", height: "30%" }} value={contactMessage} onChange={updateContactMessage} />
									<div style={{ height: "2vh" }} />

									<div style={{ display: "flex", overflow: "hidden", alignItems: "center" }} >
										<button disabled={messageSubmitted} style={{ height: "2.5vh", fontFamily: "bebas", fontSize: "1.5vh" }} type={"submit"}>Submit</button>
										<Loader
											color={Colors.pink}
											width={"2.25vh"}
											height={"2.25vh"}
											type="TailSpin"
											style={{
												marginLeft: "1.5vh",
												display: spinnerDisplay
											}}
										/>
										<p style={{ marginTop: 0, marginBottom: 0, marginLeft: "1.5vh", fontFamily: "Roboto", fontSize: "1.5vh" }}>{feedbackInfo}</p>
									</div>

									<div style={{ height: "5vh" }} />

								</form>

							</div>
						</Modal>
					)
			}

			<div
				style={{
					position: "fixed",
				}}
			>
				<div
					style={{
						width: "100vw",
						height: "100vh",
						position: "absolute"
					}}
				>

					<div
						style={{
							zIndex: -2,
							width: "100%",
							height: "100%",
							position: "absolute",
							backgroundColor: Colors.offBlack
						}}
					/>
					<AsyncLoadVideo src={logo} />
					<div
						style={{
							width: "100%",
							height: "100%",
							position: "absolute",
							backgroundColor: "rgba(0,0,0,0.75)"
						}}
					/>

				</div>
			</div>

			<div
				style={{
					width: "100%",
					height: "100%",
					fontFamily: "Bebas",
					position: "absolute",
					color: Colors.offwhite,
				}}
			>
				{
					deviceType === "desktop" ?
						(
							<Header />
						)
						:
						(
							null
						)
				}
				<h2
					style={{
						marginTop: "-1vw",
						marginBottom: 0,
						position: "absolute",
						top: "calc(50% - 1.25vw)",
						left: "50%",
						marginRight: "-50%",
						transform: "translate(-50%, -50%)",
						fontWeight: 200,
						fontSize: t1Size,
						color: Colors.pink,
						textShadow: "0 0 1.5vw #000"
					}}
				>
					<Fade bottom cascade>
						BOSS SQUAD TRAINING
					</Fade>
				</h2>
			</div>


			<Fade bottom>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "space-evenly",
						width: "100%",
						height: "50% ",
						position: "absolute",
						top: "50% ",
					}}
				>
					{/* <a href={"/training"}>
                                <button
                                    className="btn draw-border"
                                    style={{
                                        fontSize: t2Size,
                                        fontFamily: "bebas",
                                    }}
                                >
                                    Book Online
                                </button>
                            </a> */}

					<div
						style={{
							display: 'flex',
							alignItems: "center",
							justifyContent: "center"
						}}
					>

						<button
							aria-label="Contact Us"
							className={"chatheadbutton"}
							style={{
								width: mailSize,
								height: mailSize,
								border: "none",
								background: "none",
								backgroundColor: "none",
								display: "flex",
								padding: "0",
								position: "absolute"
							}}
						>
							<img
								alt="Contact us"
								src={MailIcon}
								onClick={openModal}
								// onClick={() => window.open('https://us5.list-manage.com/contact-form?u=d758904eb83e635ac95f06c88&form_id=72491e29b981e4d0d80e961a70664ee6', '_blank')}
								style={{
									width: mailSize,
									cursor: "pointer",
									position: "absolute",
								}}
							/>
							<img
								alt="Contact us"
								className={"chatheadglow"}
								src={MailIconPink}
								onClick={openModal}
								// onClick={() => window.open('https://us5.list-manage.com/contact-form?u=d758904eb83e635ac95f06c88&form_id=72491e29b981e4d0d80e961a70664ee6', '_blank')}
								style={{
									width: mailSize,
									cursor: "pointer",
									position: "absolute",
								}}
							/>
						</button>
						<p
							className={"chatTextGlow"}
							style={{
								color: Colors.offwhite,
								fontFamily: "RobotoSlab",
								fontSize: "1vw",
								marginTop: 0,
								marginBottom: 0,
								position: "relative",
								left: "7vw"
							}}
						>
							Questions? Concerns? <br /> Send us an email!
						</p>
					</div>



					<button
						aria-label="Move page down"
						className={"homeScrollArrowDown"}
						style={{
							height: arrowSize,
							background: "none",
							border: "none",
							cursor: "none",
							opacity: 0,
							PointerEvent: "none",
						}}
					>
						{/* <img
                                    alt="Down arrow"
                                    src={arrowWhite}
                                    style={{
                                        height: "100%",
                                    }}
                                /> */}
					</button>

				</div>

			</Fade>


			<div
				style={{
					zIndex: 4,
					top: "-22vh",
					width: "100vw",
					height: "180vh",
					// position: "absolute",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					fontFamily: "RobotoSlab"
				}}
			>
				{/* <div
                            style={{
                                zIndex: 100,
                                height: "7vh",
                                width: "100vw",
                                display: "flex",
                                color: Colors.grey,
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: Colors.offBlack,
                                fontSize: p2Size,
                                marginTop: "auto"
                            }}
                        >
                            <p>© Boss Squad Training 2021. All Rights Reserved.</p>
                            <p style={{ position: "absolute", right: "1.5vw" }}></p>
                        </div> */}


				<div
					style={{
						zIndex: 4,
						top: "22vh",
						width: "100vw",
						height: "93vh",
						marginTop: '100vh',
						paddingTop: "32vh",
						paddingBottom: "33vh",
						backgroundColor: Colors.pink,
						color: Colors.offBlack,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "space-evenly"
					}}
				>

					<button
						aria-label="Move page up"
						className={"homeScrollArrowUp"}
						style={{
							height: arrowSize,
							background: "none",
							border: "none",
							opacity: 0,
							PointerEvent: "none",
							cursor: "none",
						}}
					>
						{/* <img
                                    alt="Up arrow"
                                    src={arrowBlack}
                                    style={{
                                        height: "100%",
                                        transform: "rotate(180deg)",
                                    }}
                                /> */}
					</button>

					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<h1 style={{ fontFamily: "bebas", fontSize: t3Size, fontWeight: 100, marginTop: 0, textAlign: "center" }}>You're so close to being totally boss!</h1>
						<p style={{ fontSize: pSize, width: "75%", textAlign: "center", marginBottom: 0, }}>Join hundreds of subscribers who recommend Boss Squad Training today! You'll opt in to receiving Boss Squad Training promotions, newsletters, exclusive access to events and more. Start your journey in less than two minutes below.</p>

					</div>
					<a href="/subscribe">

						<button
							className="btn draw-border-2"
							style={{
								fontSize: t2Size,
								fontFamily: "bebas",
								marginTop: "10vh"
							}}
						>
							Subscribe to Our Mailing List
						</button>

					</a>

				</div>
			</div>

			{/* <Modal
				isOpen={modalOpen}
				onRequestClose={closeModal}
				style={{
					overlay: {
						background: "rgba(0,0,0,0.75)"
					},
					content: {
						width: "25vw",
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					},
				}}
			>
				<form onSubmit={handleSubmit}>
					<h2>Subscribe to our mailing list</h2>
					<label>Email:</label>
					<input
						type="email"
						value={email}
						onChange={handleEmailChange}
						disabled={isSubmitting}
					/>
					<label>Message:</label>
					<textarea
						value={message}
						onChange={handleMessageChange}
						disabled={isSubmitting}
					/>
					<button type="submit" disabled={isSubmitting}>Submit</button>
					{isSubmitting && (
						<Loader
							color={Colors.pink}
							width={"2.25vh"}
							height={"2.25vh"}
							type="TailSpin"
						/>
					)}
					<p>{feedbackInfo}</p>
				</form>
			</Modal> */}
		</div >
	);
}

const AsyncLoadVideo = (props) => {

	const [loadedSrc, setLoadedSrc] = React.useState(null);

	React.useEffect(() => {

		setLoadedSrc(null);

		if (props.src) {

			const handleLoad = () => {
				setLoadedSrc(props.src);
			};

			const image = new Image();

			image.addEventListener('load', handleLoad);
			image.src = props.src;

			return () => {
				image.removeEventListener('load', handleLoad);
			};

		}
	}, [props.src]);

	if (loadedSrc === props.src) {
		return (
			<video
				style={{
					top: 0,
					left: 0,
					zIndex: -1,
					width: "100%",
					height: "100%",
					objectFit: "cover",
					position: "absolute",
				}}
				muted
				loop
				autoPlay
				src={landingVid}
				className='videoTag'
			>
				<source src={landingVid} type='video/webm' />
				<source src={landingVidMp4} type='video/mp4' />
			</video>
		);
	}
	else {
		return null;
	}
};

export default Home;